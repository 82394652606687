<template>
  <div>
    <h2 class="font-weight-bolder mb-2 hGoBack">
      <span @click="$router.go(-1)">
        <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
          <feather-icon icon="ArrowLeftCircleIcon" size="35" />
        </b-button>
      </span> Crear cliente
    </h2>
    <b-card>
      <!-- form -->
      <validation-observer ref="createClient">
        <b-form class="mt-2" @submit.prevent="handleSubmit" @keydown.enter="handleSubmit">
          <b-row>
            <b-col sm="6">
              <b-form-group :label="'Code'" label-for="client-code">
                <b-form-input v-model="code" :placeholder="'Code'" />
              </b-form-group>
              <validation-provider #default="{ errors }" :name="'Nombre'" rules="required">
                <b-form-group :label="'Nombre*'" label-for="client-name">
                  <b-form-input v-model="name" name="name" :placeholder="'Nombre*'" />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <validation-provider #default="{ errors }" :name="'Teléfono'" rules="required">
                <b-form-group :label="'Teléfono*'" label-for="client-phone">
                  <b-form-input v-model="phone" name="reference" :placeholder="'Teléfono*'" />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <validation-provider #default="{ errors }" :name="'Dirección'" rules="required">
                <b-form-group :label="'Dirección*'" label-for="client-address">
                  <b-form-input v-model="address" name="reference" :placeholder="'Dirección*'" />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <validation-provider #default="{ errors }" :name="'Localidad'" rules="required">
                <b-form-group :label="'Localidad*'" label-for="client-city">
                  <b-form-input v-model="city" name="town" :placeholder="'Localidad*'" />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <b-form-group :label="'Método de pago'" label-for="product-paymenttype">
                <v-select v-model="paymenttype" label="title" :options="paymentstypes" :filterable="false"
                  :searchable="false">
                  <template slot="option" slot-scope="option">
                    {{ option.name }}
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    {{ option.name }}
                  </template>
                </v-select>
              </b-form-group>
              <b-form-group :label="'Descripción'" label-for="location-description">
                <quill-editor v-model="description" />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <validation-provider #default="{ errors }" :name="'CIF'" rules="required">
                <b-form-group :label="'CIF*'" label-for="client-cif">
                  <b-form-input v-model="cif" name="reference" :placeholder="'CIF*'" />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <validation-provider #default="{ errors }" :name="'Email'" rules="email|required">
                <b-form-group :label="'Email*'" label-for="client-email">
                  <b-form-input v-model="emailInput" name="reference" :placeholder="'Email*'" />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <validation-provider #default="{ errors }" :name="'Código postal'" rules="required">
                <b-form-group :label="'Código postal*'" label-for="client-postalCode">
                  <b-form-input v-model="postalCode" name="reference" :placeholder="'Código postal*'" />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <b-form-group :label="$t('Pais')" label-for="register-country-client">
                <validation-provider #default="{ errors }" :name="$t('Pais')" rules="required">
                  <v-select 
                    id="register-country-client" 
                    @input="handlerClientCountry"
                    :value="country"
                    :state="errors.length > 0 ? false : null"
                    :options="listCountries"
                    label="name"
                    :clearable="false"
                    name="register-country-client" 
                    :placeholder="$t('Pais')" 
                  />
                  <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group :label="$t('Provincia')" label-for="register-province-client">
                <validation-provider #default="{ errors }" :name="$t('Provincia')" rules="required">
                  <v-select
                    v-if="country && country.code === 'ES'" 
                    v-model="ClientProvince" 
                    label="name" 
                    :options="selectProvinces" 
                    :filterable="true"
                    :searchable="true" 
                    :placeholder="$t('Provincia')" 
                  />
                  <b-form-input
                    v-else 
                    v-model="ClientProvince"
                    :placeholder="$t('Provincia')"
                    :disabled="!country"
                  />
                  <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group :label="'Web'" label-for="client-web">
                <b-form-input v-model="web" name="reference" :placeholder="'Web'" />
              </b-form-group>

              <b-form-group label="Tipo de empresa" label-for="register-tipo-empresa">
                <validation-provider #default="{ errors }" name="Empresa" rules="required">
                  <v-select v-model="type" :options="selectTypeCompany" :filterable="true"
                            :searchable="true" placeholder="Tipo de empresa" />
                  <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="'Imagen'" label-for="client-image">
                <ImageDropzone ref="image" :files="files" maxFiles="1" />
              </b-form-group>
            </b-col>
            <b-col cols="12 text-right">
              <b-button type="submit" variant="primary" class="mt-2 mr-1">
                {{ 'Enviar' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BLink,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    ImageDropzone,
    BLink,
    quillEditor,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      code: '',
      cif: '',
      address: '',
      postalCode: '',
      city: '',
      emailInput: '',
      web: '',
      phone: '',
      country: '',
      paymenttype: '',
      type: null,
      files: [],
      required,
      email,
      ClientProvince: '',
      description: '',
    }
  },
  computed: {
    ...mapGetters({
      countries: 'countries/getCountries',
      client: 'clients/getClient',
      paymentstypes: 'payments/getSelectPayments',
      selectProvinces: 'provinces/getSelectProvinces',
      selectTypeCompany: 'config/getSelectTypeCompany',
      currentLanguage: 'languages/getCurrentLanguage',
    }),
    listCountries () {
      return this.countries.map((countrie) => {
        countrie.name = countrie.title[this.currentLanguage]
        return countrie
      })
    },
  },
  methods: {
    ...mapActions({
      create: 'clients/create',
      getClient: 'clients/getClient',
      getSelectPayments: 'payments/selectPayments',
      getSelectProvinces: 'provinces/selectProvinces',
      getSelectTypeCompany: 'config/getSelectTypeCompany',
      getListCountries: 'countries/getListCountries',
    }),
    handleSubmit() {
      this.$refs.createClient.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.create({ client: formData })
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end', 
          });
        }
      })
    },
    createFormData() {
      const data = this.$refs.image.getFormData('image')
      data.append('code', this.code)
      data.append('name', this.name)
      data.append('description', this.description)
      data.append('cif', this.cif)
      data.append('address', this.address)
      data.append('postalCode', this.postalCode)
      data.append('country_id', this.country.id)
      data.append('city', this.city)
      data.append('phone', this.phone)
      data.append('email', this.emailInput)
      data.append('province', this.country.code === 'ES' ? this.ClientProvince.id : this.ClientProvince)

      if (this.paymenttype)
        data.append('paymenttype_id', this.paymenttype.id)
      else
        data.append('paymenttype_id', '')
      data.append('web', this.web)
      data.append('type', this.type)

      // console.log('form', this)

      // return false
      return data
    },
    handlerClientCountry(value) {
      this.country = value
      this.province = ''
    }
  },
  async created() {
    await this.getSelectTypeCompany()
    await this.getSelectPayments({ page: 1, per_page: 999 })
    await this.getSelectProvinces({ page: 1, per_page: 9999, search: '', orderBy: '' })
    await this.getListCountries({
      page: 1, per_page: 9999, search: '', orderBy: '',
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
