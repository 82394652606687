var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"font-weight-bolder mb-2 hGoBack"},[_c('span',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-button',{staticClass:"btn-icon rounded-circle mr-50 text-danger bg-white",staticStyle:{"padding":"0.5px"},attrs:{"variant":"white"}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftCircleIcon","size":"35"}})],1)],1),_vm._v(" Crear cliente ")]),_c('b-card',[_c('validation-observer',{ref:"createClient"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":'Code',"label-for":"client-code"}},[_c('b-form-input',{attrs:{"placeholder":'Code'},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_c('validation-provider',{attrs:{"name":'Nombre',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Nombre*',"label-for":"client-name"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":'Nombre*'},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":'Teléfono',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Teléfono*',"label-for":"client-phone"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Teléfono*'},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":'Dirección',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Dirección*',"label-for":"client-address"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Dirección*'},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":'Localidad',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Localidad*',"label-for":"client-city"}},[_c('b-form-input',{attrs:{"name":"town","placeholder":'Localidad*'},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-form-group',{attrs:{"label":'Método de pago',"label-for":"product-paymenttype"}},[_c('v-select',{attrs:{"label":"title","options":_vm.paymentstypes,"filterable":false,"searchable":false},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}]),model:{value:(_vm.paymenttype),callback:function ($$v) {_vm.paymenttype=$$v},expression:"paymenttype"}})],1),_c('b-form-group',{attrs:{"label":'Descripción',"label-for":"location-description"}},[_c('quill-editor',{model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'CIF',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'CIF*',"label-for":"client-cif"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'CIF*'},model:{value:(_vm.cif),callback:function ($$v) {_vm.cif=$$v},expression:"cif"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":'Email',"rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Email*',"label-for":"client-email"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Email*'},model:{value:(_vm.emailInput),callback:function ($$v) {_vm.emailInput=$$v},expression:"emailInput"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":'Código postal',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Código postal*',"label-for":"client-postalCode"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Código postal*'},model:{value:(_vm.postalCode),callback:function ($$v) {_vm.postalCode=$$v},expression:"postalCode"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-form-group',{attrs:{"label":_vm.$t('Pais'),"label-for":"register-country-client"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Pais'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"register-country-client","value":_vm.country,"state":errors.length > 0 ? false : null,"options":_vm.listCountries,"label":"name","clearable":false,"name":"register-country-client","placeholder":_vm.$t('Pais')},on:{"input":_vm.handlerClientCountry}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Provincia'),"label-for":"register-province-client"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Provincia'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.country && _vm.country.code === 'ES')?_c('v-select',{attrs:{"label":"name","options":_vm.selectProvinces,"filterable":true,"searchable":true,"placeholder":_vm.$t('Provincia')},model:{value:(_vm.ClientProvince),callback:function ($$v) {_vm.ClientProvince=$$v},expression:"ClientProvince"}}):_c('b-form-input',{attrs:{"placeholder":_vm.$t('Provincia'),"disabled":!_vm.country},model:{value:(_vm.ClientProvince),callback:function ($$v) {_vm.ClientProvince=$$v},expression:"ClientProvince"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":'Web',"label-for":"client-web"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Web'},model:{value:(_vm.web),callback:function ($$v) {_vm.web=$$v},expression:"web"}})],1),_c('b-form-group',{attrs:{"label":"Tipo de empresa","label-for":"register-tipo-empresa"}},[_c('validation-provider',{attrs:{"name":"Empresa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.selectTypeCompany,"filterable":true,"searchable":true,"placeholder":"Tipo de empresa"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Imagen',"label-for":"client-image"}},[_c('ImageDropzone',{ref:"image",attrs:{"files":_vm.files,"maxFiles":"1"}})],1)],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s('Enviar')+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }